var mapConfig = {
  freetown: {
    zoom: 8,
    center: {lat: 8.665148, lng: -11.788725}
 	},
  TheHaitiTreeProject : {
    zoom: 8,
    center: {lat: 18.5296, lng: -72.4081}
  }
};

export { mapConfig };
